import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {init as initFullStory} from '@fullstory/browser';
import {environment} from '@simplifi/env/environment';

@Component({
  selector: 'simplifi-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private readonly router: Router) {
    if (environment.fsOrgId) {
      initFullStory({
        orgId: environment.fsOrgId,
        debug: environment.fsDebug,
        namespace: environment.fsNamespace,
        recordCrossDomainIFrames: true,
        recordOnlyThisIFrame: false,
        devMode: environment.fsDevMode,
      });
    }
  }
  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const mainContainer = document.querySelector('#main-container');
        if (mainContainer) {
          mainContainer.scrollTop = 0; // Scroll to top of the main-container
        }
      });
  }
}
